var modalClosingEvent = new Event("modal-closing");
var modalOpeningEvent = new Event("modal-opening");
var modalOpenerCalledEvent = new Event("modal-opener-called");

var init = function init() {
  [].slice.call(document.querySelectorAll(".modal-opener")).map(function(el) {
    el.addEventListener(
      "click",
      function(e) {
        el.dispatchEvent(modalOpenerCalledEvent);
        var target = el.getAttribute("data-target");
        var targetModal = document.querySelector(".modal[data-target='" + target + "']");
        targetModal.classList.add("is-active");
        targetModal.dispatchEvent(modalOpeningEvent);
      },
      false
    );
  });

  [].slice.call(document.querySelectorAll(".modal")).map(function(modal) {
    var modalBackground = modal.querySelector(".modal-background");
    var modalClose = modal.querySelector(".modal-close-button");
    var modalCancelButton = modal.querySelector(".modal-cancel-button");
    var closeModal = function closeModal(event) {
      event.preventDefault();
      modal.classList.remove("is-active");
      modal.dispatchEvent(modalClosingEvent);
      window.dispatchEvent(new Event("modal-closed-" + modal.dataset.target));
    };
    if (modalBackground) {
      modalBackground.addEventListener("click", closeModal, false);
    }
    if (modalClose) {
      modalClose.addEventListener("click", closeModal, false);
    }
    if (modalCancelButton) {
      modalCancelButton.addEventListener("click", closeModal, false);
    }
  });
  window.dispatchEvent(new Event("modals-ready"));
};
window.addEventListener("load", init, false);
